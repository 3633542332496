<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="py-0">
                <h2 class="text-center custom-header">User Access</h2>
                <v-list flat>
                    <v-subheader>
                        <v-checkbox label="Seelct All" id="selectAll" @click="checkAll" hide-details></v-checkbox>
                    </v-subheader>
                </v-list>
            </v-col>
            <v-col cols="3" xs="12" sm="6" md="4" class="py-0 pb-3">
                <v-card height="100%" class="pb-2">
                    <v-list flat class="group">
                        <v-subheader>
                            <v-checkbox @click="onClickGroupHeads" class="group-head" label="Employee Module" hide-details></v-checkbox>
                        </v-subheader>
                        <div class="group-child">
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Employee Entry" value="Employee Entry" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Employee List" value="Employee List" hide-details></v-checkbox>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-card>
            </v-col>

            <v-col cols="3" xs="12" sm="6" md="4" class="py-0 pb-3">
                <v-card height="100%" class="pb-2">
                    <v-list flat class="group">
                        <v-subheader>
                            <v-checkbox @click="onClickGroupHeads" class="group-head" label="Attendance Module" hide-details></v-checkbox>
                        </v-subheader>

                        <div class="group-child">
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Manual Attendance" value="Manual Attendance" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Attendance Process" value="Attendance Process" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Attendance Report" value="Attendance Report" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Attendance Summary Report" value="Attendance Summary Report" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Attendance Log" value="Attendance Log" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Present List" value="Present List" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Late List" value="Late List" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Absent List" value="Absent List" hide-details></v-checkbox>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="3" xs="12" sm="6" md="4" class="py-0 pb-3">
                <v-card height="100%" class="pb-2">
                    <v-list flat class="group">
                        <v-subheader>
                            <v-checkbox @click="onClickGroupHeads" class="group-head" label="Leave Module" hide-details></v-checkbox>
                        </v-subheader>

                        <div class="group-child">
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Leave Type" value="Leave Type" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Leave Entry" value="Leave Entry" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Leave List" value="Leave List" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Leave Record" value="Leave Record" hide-details></v-checkbox>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="3" xs="12" sm="6" md="4" class="py-0 pb-3">
                <v-card height="100%" class="pb-2">
                    <v-list flat class="group">
                        <v-subheader>
                            <v-checkbox @click="onClickGroupHeads" class="group-head" label="Loan Module" hide-details></v-checkbox>
                        </v-subheader>

                        <div class="group-child">
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Loan Entry" value="Loan Entry" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Loan Adjustment" value="Loan Adjustment" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Loan List" value="Loan List" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Loan Statement" value="Loan Statement" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Loan Balance Report" value="Loan Balance Report" hide-details></v-checkbox>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="3" xs="12" sm="6" md="4" class="py-0 pb-3">
                <v-card height="100%" class="pb-2">
                    <v-list flat class="group">
                        <v-subheader>
                            <v-checkbox @click="onClickGroupHeads" class="group-head" label="Salary Module" hide-details></v-checkbox>
                        </v-subheader>

                        <div class="group-child">
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Transaction" value="Transaction" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Transaction Record" value="Transaction Record" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Salary Generate" value="Salary Generate" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Salary Report" value="Salary Report" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Bank Sheet" value="Bank Sheet" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Pay Slip" value="Pay Slip" hide-details></v-checkbox>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="3" xs="12" sm="6" md="4" class="py-0 pb-3">
                <v-card height="100%" class="pb-2">
                    <v-list flat class="group">
                        <v-subheader>
                            <v-checkbox @click="onClickGroupHeads" class="group-head" label="Bonus Module" hide-details></v-checkbox>
                        </v-subheader>

                        <div class="group-child">
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Bonus Generate" value="Bonus Generate" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Bonus Report" value="Bonus Report" hide-details></v-checkbox>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="3" xs="12" sm="6" md="4" class="py-0 pb-3">
                <v-card height="100%" class="pb-2">
                    <v-list flat class="group">
                        <v-subheader>
                            <v-checkbox @click="onClickGroupHeads" class="group-head" label="PF Module" hide-details></v-checkbox>
                        </v-subheader>

                        <div class="group-child">
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="PF Transaction" value="PF Transaction" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="PF Transaction Record" value="PF Transaction Record" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="PF Balance Report" value="PF Balance Report" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="PF Ledger" value="PF Ledger" hide-details></v-checkbox>
                            </v-list-item>
                         </div>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="3" xs="12" sm="6" md="4" class="py-0 pb-3">
                <v-card height="100%" class="pb-2">
                    <v-list flat class="group">
                        <v-subheader>
                            <v-checkbox @click="onClickGroupHeads" class="group-head" label="Administration Module" hide-details></v-checkbox>
                        </v-subheader>

                        <div class="group-child">
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Company Profile" value="Company Profile" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Department Entry" value="Department Entry" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Designation Entry" value="Designation Entry" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Month Entry" value="Month Entry" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Shift Entry" value="Shift Entry" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Shift Management" value="Shift Management" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Holiday Management" value="Holiday Management" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Area Entry" value="Area Entry" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Country Entry" value="Country Entry" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Bank Entry" value="Bank Entry" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Increment Entry" value="Increment Entry" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Promotion Entry" value="Promotion Entry" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="Notice Management" value="Notice Management" hide-details></v-checkbox>
                            </v-list-item>
                            <v-list-item>
                                <v-checkbox class="access" v-model="access" label="User Management" value="User Management" hide-details></v-checkbox>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="12" md="12" class="text-right">
                <v-btn
                    height="30px"
                    dark
                    color="light-blue darken-2 mr-1"
                    :elevation="1"
                    type="button"
                    :loading="loading"
                    @click="updateAccess"
                >Save</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import utility from '../../mixins/utility.mixin';
export default {
    name: "UserAccess",
    mixins: [utility],
    data() {
        return {
            access: [],
            loading: false,
        }
    },
    mounted() {
        let accessCheckboxes = document.querySelectorAll('.access .v-input--selection-controls__ripple');
        accessCheckboxes.forEach(checkbox => {
            checkbox.addEventListener('click', async () => {
                await new Promise(r => setTimeout(r, 200));
                this.makeChecked();
            })
        })
    },
    async created(){
        await this.getUserAccess();
        this.makeChecked();
    },
    methods: {
        async updateAccess(){
            this.loading = true;
            await axios.post(`/update_user_access/${this.$route.params.id}`, this.access).then(res=>{
                this.$store.dispatch('snackbar/success', res.data.message);
            });
            this.loading = false;
            this.$router.push('/user_management');
        },
        async getUserAccess(){
            await axios.get(`/get_user_access/${this.$route.params.id}`).then(res=>{
                this.access = res.data;
            })
        },
        async onClickGroupHeads() {
            let groupHead  = event.target;
            let groupHead2 = groupHead;
            if(groupHead.nodeName == 'DIV'){
                groupHead2 = groupHead.previousSibling;
            }
            await new Promise(r => setTimeout(r, 200));
            let access = groupHead2.closest('.group');
            let accessCheckboxes = access.querySelectorAll('.access input');
            if(groupHead2.checked){
                accessCheckboxes.forEach(checkbox => {
                    this.access.push(checkbox.value);
                })
            } else if(groupHead.nodeName == 'DIV') {
                accessCheckboxes.forEach(checkbox => {
                    let ind = this.access.findIndex(a => a == checkbox.value);
                    this.access.splice(ind, 1);
                })
            }
            this.access = this.access.filter((v, i, a) => a.indexOf(v) === i);
            await new Promise(r => setTimeout(r, 200));
            this.makeChecked();
        },
        async checkAll(){
            let nodeName = event.target.nodeName;
            await new Promise(r => setTimeout(r, 200));
            if(document.querySelector('#selectAll').checked){
                let accessCheckboxes = document.querySelectorAll('.access input');
                accessCheckboxes.forEach(checkbox => {
                    this.access.push(checkbox.value)
                })
            }else if(nodeName == 'DIV'){
                this.access = [];
            }
            this.access = this.access.filter((v, i, a) => a.indexOf(v) === i);
            await new Promise(r => setTimeout(r, 200));
            this.makeChecked();
        },
        makeChecked(){
            let groups = document.querySelectorAll('.group');
            groups.forEach(group => {
                let groupHead = group.querySelector('.group-head input');
                let accessCheckboxes = group.querySelectorAll('.group-child input').length;
                let checkedAccessCheckBoxes = group.querySelectorAll('.group-child input:checked').length;
                if(accessCheckboxes == checkedAccessCheckBoxes && !groupHead.checked){
                    groupHead.click();
                } else if(accessCheckboxes != checkedAccessCheckBoxes && groupHead.checked) {
                    groupHead.click();
                }
            })

            let selectAllCheckbox = document.querySelector('#selectAll');
            let totalAccessCheckboxes = document.querySelectorAll('.access input').length;
            let totalCheckedAccessCheckBoxes = document.querySelectorAll('.access input:checked').length;
            if(totalAccessCheckboxes == totalCheckedAccessCheckBoxes && !selectAllCheckbox.checked){
                selectAllCheckbox.click();
            } else if(totalAccessCheckboxes != totalCheckedAccessCheckBoxes && selectAllCheckbox.checked) {
                selectAllCheckbox.click();
            }
        }
    },
}
</script>

<style scoped>
>>>.v-list-item{
    padding: 0;
    padding-left: 40px;
}

>>>.v-input--selection-controls{
    padding-top: unset;
    margin-top: unset;
}

>>>.v-subheader label{
    font-weight: bold;
}

.custom-header{
    font-size: 16px;
    font-weight: bold;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    text-transform: uppercase;
    padding: 5px;
}
</style>